import React from 'react';
import PropTypes from 'prop-types';
import { imageSizesLookup } from '../../../utils/cloudinary_asset_util';
import { setModifierClass } from '../../../utils/create_class_list_util';
import Slug from '../slug/slug';
import Card from '../card/card';
import Advertorial, { isAdvertorial } from '../advertorial/advertorial';
import { trackGAEvent } from '../../../client/utils/third_party_trackers/ga';
import { WhatsThis } from '../fc_executive_board';

function buildSlugWrapperClass(advertorial) {
  const className = 'homepage-tag__advertorial-title-container';
  // TODO: change class modifier on setModifierClass
  const slugTitle =
    setModifierClass(advertorial, className, 'advertorial') || className;
  return slugTitle;
}

function renderSlug(data, tags, advertorial, hasSponsoredContentLabel) {
  const pageLink = data?.slug ? `/section/${data.slug}` : '';
  const slugName = data?.name || 'more stories';
  const tagLine = data?.tagLine;

  return (
    <div className={buildSlugWrapperClass(advertorial)}>
      <div>
        <Slug
          slugName={slugName}
          linkTo={pageLink}
          hasSponsoredContentLabel={hasSponsoredContentLabel}
        />

        {data?.slug === 'fast-company-executive-board' && <WhatsThis />}

        {advertorial ? (
          <Advertorial
            modifier="homepage-deck"
            tags={tags}
            section="homepage"
          />
        ) : (
          ''
        )}
        {tagLine ? <h3 className="advertorial__module-deck">{tagLine}</h3> : ''}
      </div>
    </div>
  );
}

export default function MoreStories(props) {
  if (!props) {
    return null;
  }

  const {
    config,
    metadata,
    slugData,
    tags,
    isPost,
    noBottomBorder,
    hasSponsoredContentLabel
  } = props;
  const advertorial = isAdvertorial(tags);

  return (
    <section className={isPost ? 'post-more-stories' : 'more-stories-module'}>
      {renderSlug(slugData, tags, advertorial, hasSponsoredContentLabel)}
      <div className="row">
        {metadata.map((post, i, arr) => (
          <Card
            key={post.id}
            metadata={{
              ...post,
              assetSizes:
                i === 0
                  ? imageSizesLookup.bigFirstMobile
                  : imageSizesLookup[arr.length]
            }}
            top={i === 0}
            tags={tags}
            noBottomBorder={noBottomBorder}
            config={config}
            totalCards={arr.length}
            cardEvent={() => {
              trackGAEvent({
                eventCategory: 'Engagement',
                eventAction: `HomepageTag:Pos-${i + 1}`,
                eventLabel: post.link
              });
            }}
          />
        ))}
      </div>
    </section>
  );
}

MoreStories.defaultProps = {
  tags: [],
  isPost: false,
  slugData: {},
  noBottomBorder: false,
  hasSponsoredContentLabel: false
};

MoreStories.propTypes = {
  config: PropTypes.shape({}).isRequired,
  metadata: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  slugData: PropTypes.shape({}),
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  isPost: PropTypes.bool,
  noBottomBorder: PropTypes.bool,
  hasSponsoredContentLabel: PropTypes.bool
};
